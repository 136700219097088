<template>
  <div>
    <div class="top">
      <div class="top-font2" @click="toshopmy()">
        <div class="top-font-1">商城中心</div>
      </div>
      <div class="top-font" @mouseover="mouseOver2" @mouseleave="mouseLeave2">
        <div class="top-font-1">网站导航</div>
        <div class="daohang" v-if="isphone2">
          <div
            :class="[item.ischeck ? 'daohang-item2' : 'daohang-item']"
            v-for="(item, index) in daohang"
            :key="index"
            @mouseover="change(item)"
            @mouseleave="change2(item)"
            @click.stop="tourl(index)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="topbox">
      <div class="topbox-center">
        <div class="logo" @click="backhome()">
          <img src="../../../assets/images/white-logo.png" alt="" />
        </div>
        <div class="search">
          <img
            src="../../../assets/images/pc-search.png"
            alt=""
            @click="search()"
          />
          <input type="text" placeholder="输入关键字进行搜索" v-model="value" />
          <div class="search-title" v-if="!listnone">
            <div
              class="search-title-item"
              v-for="(item, index) in categoryList"
              :key="index"
              @click="todetail(item, index)"
            >
              {{ item.text }}
            </div>
            <div @click="tojifen()">积分商城</div>
          </div>
        </div>
        <div class="top-car" @click="toshopcar">
          <div :class="[shopcar == 0 ? 'num2' : ' num']">{{ shopcar }}</div>
          <div>购物车</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { qeryShopGoodsList, queryGoodsCategoryTree } from "@/api/shop";
import { mapState } from 'vuex'
export default {
  props: {
    listnone: Boolean,
  },
  data () {
    return {
      shopcar: 0,
      value: '',
      categoryList: [

      ],
      searchlist: [],
      isphone: false,
      isphone2: false,
      daohang: [
        {
          name: '首页',
          ischeck: true,
        },
        {
          name: '精选课程',
          ischeck: false,
        },
        {
          name: '资料下载',
          ischeck: false,
        },
        {
          name: '在线题库',
          ischeck: false,
        },
        {
          name: '在线艺术',
          ischeck: false,
        },
        {
          name: '艺术交流',
          ischeck: false,
        },
      ],
    }
  },
  created () {
    this.postQeryShopGoodsCategoryList()
    console.log(this.shopcarnum)
    //初始化购物车数量
    this.shopcar = localStorage.getItem('shopnum')
  },
  mounted () {

  },
  watch: {
    "$store.state.shopcarnum": {
      deep: true,
      handler (n, o) {
        console.log(n, "NNNN")
      }
    },
    carNum (n, o) {
      console.log(n, "pppp")
      this.shopcar = n
    }
  },
  computed: {
    ...mapState(['shopcarnum']),
    carNum () {
      return this.$store.state.shopcarnum
    }
  },
  methods: {
    async postQeryShopGoodsCategoryList () {
      let parm = {};
      const res = await queryGoodsCategoryTree(parm);
      this.categoryList = res.response.slice(0, 6);

    },
    async search () {
      await this.postQeryShopGoodsList();
      this.$emit("search", this.searchlist);
      this.$router.push({ path: "/PCshop/goods/list?key=" + this.value });
    },

    mouseOver1 () {
      console.log(1);
      this.isphone = true;
    },
    mouseLeave1 () {
      console.log(2);
      this.isphone = false;
    },
    mouseOver2 () {
      this.isphone2 = true;
    },
    mouseLeave2 () {
      this.isphone2 = false;
    },
    change (item) {
      console.log(item);
      item.ischeck = true;
    },
    change2 (item) {
      item.ischeck = false;
    },
    Tzdetail (n) {
      this.$router.push({
        name: "PCshop/goods/list",
        params: {
          categoryId: n
        }
      });
    },
    todetail (item) {
      this.Tzdetail(item.id)
    },
    tourl (idx) {
      if (idx == 0) {
        this.$router.push({
          path: "/home",
        });
      }
      if (idx == 1) {
        this.$router.push({
          path: "/course/index",
        });
      }
      if (idx == 2) {
        this.$router.push({
          path: "/dataDownload",
        });
      }
      if (idx == 3) {
        this.$router.push({
          path: "/onlineQuestionBank",
        });
      }
      if (idx == 4) {
        this.$router.push({
          path: "/art",
        });
      }
      if (idx == 5) {
        this.$router.push({
          path: "/bbs",
        });
      }
    },
    //请求搜索数据
    async postQeryShopGoodsList () {
      let parm = {};
      parm.pageIndex = this.pageIndex;
      parm.pageSize = this.pageSize;
      parm.IsShelf = 1;
      parm.pageIndex = this.pageIndex;
      parm.pageSize = this.pageSize;
      parm.CategoryId = this.categoryId;
      parm.minPrice = this.minPrice;
      parm.maxPrice = this.maxPrice;
      parm.maxPoints = this.maxPoints;
      parm.minPoints = this.minPoints;
      parm.keyWords = this.value;
      parm.sortType = this.sortType;
      parm.sortField = this.sortField;
      const res = await qeryShopGoodsList(parm);
      console.log(res, 888888888);
      this.total = res.dataCount; //总数
      // this.goodsRecommendList = res.data;
      this.searchlist = res.data;
      console.log(this.searchlist, "search");
      this.isLoad = false;
      if (res.data.length == 0) {
        this.isEnd = true;
      }
    },
    tojifen () {
      this.$router.push({ path: "/PCshop/goods/integral" });
    },
    toshopcar () {
      if (localStorage.getItem("userInfo") != null) {
        this.$router.push({
          path: "/PCshop/ShopCar",
        });
      } else {
        this.$router.push({
          path: "/login",
        });
      }
    },
    toshopmy () {
      if (localStorage.getItem("userInfo") != null) {
        this.$router.push({
          path: "/PCshop/my/shopmy",
        });
      } else {
        this.$router.push({
          path: "/login",
        });
      }
    },
    backhome () {
      this.$router.push({
        path: "/PCshop/PChome",
      });
    }
  }


}
</script>
<style lang='scss' scoped>
.top {
  display: flex;
  height: 30px;
  width: 100%;
  padding-left: 60%;
  background: #e3e4e5;
  .top-font2 {
    cursor: pointer;
    width: 90px;
    line-height: 30px;
    color: #6e6e6e;
    font-size: 12px;
    margin-left: 28px;
    text-align: center;
  }
  .top-font {
    width: 90px;
    background-image: url("../../../assets/images/topbottom.png");
    background-size: 20% 73%;
    background-repeat: no-repeat;
    background-position: 86% 50%;
    line-height: 30px;
    color: #6e6e6e;
    font-size: 12px;
    margin-left: 28px;
    .top-font-1 {
      cursor: pointer;
      padding-left: 15px;
    }
    .erweima {
      .erweima-img {
        width: 70px;
        height: 70px;
        margin: 0 auto;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .erweima-font {
        width: 48px;
        height: 32px;
        color: #333333;
        font-size: 12px;
        text-align: center;
        margin: 18px auto;
        line-height: 15px;
      }
    }
    .daohang {
      width: 90px;
      background: #ffffff;
      position: relative;
      z-index: 99;

      padding-left: 15px;
      .daohang-item {
        cursor: pointer;
        color: #333333;
      }
      .daohang-item2 {
        cursor: pointer;
        color: #fb4a26;
      }
    }
  }
  img {
    margin-top: 9px;
    width: 17px;
    height: 14px;
  }
}
.topbox {
  background: linear-gradient(90deg, #f34330, #fc4b24);
  width: 100%;
  height: 140px;
  .topbox-center {
    width: 1200px;
    margin: 0 auto;
    height: 100%;
    overflow: hidden;
    display: flex;
    .logo {
      cursor: pointer;
      width: 200px;
      height: 50px;
      margin-top: 45px;
    }
    .search {
      position: relative;
      margin-left: 136px;
      input {
        width: 500px;
        height: 36px;
        border-radius: 20px;
        border: 1px solid #f95e2e;
        margin-top: 50px;
        padding-left: 15px;
      }
      img {
        position: absolute;
        width: 20px;
        height: 21px;
        top: 57px;
        left: 467px;
      }
      .search-title {
        cursor: pointer;
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        color: #ffffff;
        font-size: 14px;
        opacity: 0.8;
        .search-title-item {
        }
      }
    }
    .top-car {
      cursor: pointer;
      width: 100px;
      height: 36px;
      border-radius: 20px;
      background: #ffffff;
      margin-top: 50px;
      margin-left: 30px;
      background-image: url("../../../assets/images/topcar.png");
      background-repeat: no-repeat;
      background-position: 24% 48%;
      padding-top: 8px;
      padding-left: 45px;
      color: #fc4b24;
      font-size: 13px;
      position: relative;
      .num {
        position: absolute;
        top: 3px;
        left: 26px;
        background: #fc4b24;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        color: #ffffff;
        font-size: 6px;
        text-align: center;
      }
      .num2 {
        display: none;
        position: absolute;
        top: 3px;
        left: 26px;
        background: #fc4b24;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        color: #ffffff;
        font-size: 6px;
        text-align: center;
      }
    }
  }
}

input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #999999;
}
input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #999999;
}
input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #999999;
}
input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #999999;
}
.erweima {
  width: 90px;
  height: 134px;
  background: #ffffff;
  position: relative;
  z-index: 99;
}
</style>