<template>
    <div>
        <Topbar></Topbar>
        <div class="pc-title">首页>商城中心>我的订单>申请售后</div>
        <div class="serve-content">
                <div class="goods-item" v-for="(item,index) in detailList" :key="index">
                        <div class="content-left-img">
                        <img :src="item.SpecImg" alt="">
                        </div>
                        <div class="content-left-font">
                        <div class="font1">{{item.GoodsName}}</div> 
                        <div class="font2">{{item.SpecName}}</div>
                        <div class="content-price">￥{{item.GoodsPrice}}</div>
                        </div>
                </div>
                 <div class="title2">选择服务类型</div>
                 <div class="server-select">
                     <div :class="[item.ischeck?' server-select-item2':' server-select-item']" v-for="(item,index) in selectlist" :key="index" @click="change(index,selectlist)">
                         <div class="item-left">
                             <img :src="item.img" alt="">
                         </div>
                         <div class="item-right">
                             <div class="item-right-font">{{item.font}}</div>
                             <div class="item-right-font2">{{item.font2}}</div>
                         </div>
                     </div>
                 </div>
        </div>
       <Foter></Foter>
    </div>
</template>
<script>
import { queryShopOrderDetail, applyRefund } from "@/api/shop";
import { imagesUploadPic } from "@/api/api";
import { Toast,Picker  } from "vant";
import Topbar from '../../PCshop/components/topbar.vue'
import Foter from '../../PCshop/components/shopfooter.vue'
export default {
    components:{
        Topbar,Foter
    },
    data(){
        return{
            detailList:[],
            godslist:[
                {
                    price:160,
                    src:require('../../../assets/images/shopimg.png'),
                    font:'帝品狐晶瓷画玄关装饰画轻奢现代简约北欧风抽象几何艺术入户过道 走廊墙壁挂画竖版有框',
                }
            ],
            selectlist:[
                {
                   img:require('../../../assets/images/serve1.png'),
                   font:'我要退款（无需退货）',
                   font2:'没收到货，或与卖家协商同意不用退货只退款',
                   ischeck:false
                },
                {
                   img:require('../../../assets/images/serve2.png'),
                   font:'我要退货退款',
                   font2:'已收到货，需要退还收到的货物',
                   ischeck:false
                },
                {
                   img:require('../../../assets/images/serve3.png'),
                   font:'换货',
                   font2:'商品存在质量问题，联系卖家协商换货',
                   ischeck:false
                },
            ]
        }
    },
    created(){
       this.orderId = this.$route.query.orderId;
       this.getQueryShopOrderDetail()
    },
    methods:{
         async getQueryShopOrderDetail(){
            const res = await queryShopOrderDetail(this.orderId);
            this.detail = res.response;
            for (var i = 0; i < res.response.OrderDetailList.length; i++) {
                let obj = {};
                obj = res.response.OrderDetailList[i];
                obj.RefundCount = obj.GoodsCount;
                this.detailList.push(obj);
                console.log(this.detailList,79)
            }
        },
        change(idx,arr){
          arr=arr.map(item=>{
            item.ischeck=false;
            return item
        })
         this.$set(arr[idx],'ischeck','true')
          this.$router.push({
            path: "/PCshop/service/servicedetail",
            query: { orderId:this.orderId,index:idx,detaillist:this.detailList},
        });
      },
    }
}
</script>
<style lang="scss" scoped>
  .pc-title{
        width: 1100px;
        height: 55px;
        margin: 0 auto;
        line-height: 55px;
        color: #666666;
        font-size: 12px;
    }
    .serve-content{
        width: 1100px;
        background:#FFFFFF;
        border: 1px solid #EEEEEE;
        margin: 0 auto 49px;
    }
     .title2{
            width: 113px;
            height: 24px;
            font-size: 18px;
            font-weight: bold;
            color: #333333;
            margin-top: 30px;
            margin-left: 30px;
        }
    .goods-item{
            display:flex;
            border-bottom: 1px solid #E9E9E9;
            padding-left: 21px;
            padding-top: 21px;
            padding-bottom:20px ;
           .content-left-img{
                width:120px;
                height:120px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .content-left-font{
                margin-left: 24px;
                .font1{
                    font-size: 18px;
                    width: 540px;
                    height: 52px;
                    text-overflow: -o-ellipsis-lastline;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                .font2{
                    font-size: 14px;
                    color: #999999;
                    margin-top: 15px;
                }
            }
            .content-price{
                margin-top: 13px;
                color: #F40707;
                font-weight: 800;
                font-size: 18px;
            }
        }
       .server-select{
          display: flex;
          margin-left: 30px;
          margin-right: 30px;
          margin-bottom: 30px;
          justify-content: space-between;
          flex-wrap: wrap;
          .server-select-item{
                margin-top: 20px;
                width: 500px;
                height: 120px;
                background: #FFFFFF;
                border: 1px solid #E8E8E8;
                border-radius: 2px;
                display: flex;
                padding-top: 35px;
                padding-left: 41px;
                .item-left{
                    width: 55px;
                    height: 50px;
                    .img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .item-right{
                    margin-left: 30px;
                    .item-right-font{
                        font-size: 18px;
                        font-weight: bold;
                        color: #333333;
                    }
                    .item-right-font2{
                        font-size: 16px;
                        font-weight: 400;
                        color: #9C9C9C;
                        margin-top: 8px;
                    }
                }
           }
           .server-select-item2{
                margin-top: 20px;
                width: 500px;
                height: 120px;
                background: #FFF1EC;
                border: 1px solid #F95E2E;
                border-radius: 2px;
                display: flex;
                padding-top: 35px;
                padding-left: 41px;
                .item-left{
                    width: 55px;
                    height: 50px;
                    .img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .item-right{
                    margin-left: 30px;
                    .item-right-font{
                        font-size: 18px;
                        font-weight: bold;
                        color: #333333;
                    }
                    .item-right-font2{
                        font-size: 16px;
                        font-weight: 400;
                        color: #9C9C9C;
                        margin-top: 8px;
                    }
                }
           }
       }
      
</style>