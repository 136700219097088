<template>
  <div>
    <div class="footer">
      <div class="cont">
        <div class="footer1">
          <div
            class="foofont"
            v-for="(item, index) in footerlist"
            :key="index"
            @click="tofooterdetail(index, item)"
          >
            {{ item.text }}
          </div>
        </div>
        <div class="footer2">
          <div class="foofont2">
            普利明生源教育信息咨询有限公司 版权所有 2006-2021
          </div>
          <div class="foofont2">
            统一社会信用代码：91500106MA5YWAT03E
            <span @click="goToOut">
              <a
                target="_blank"
                href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50009802000890"
                >渝公网安备 50009802000890号</a
              >
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      footerlist: [
        { text: '公司介绍' },
        { text: '公司地址' },
        { text: '法律条款' },
        { text: '免责声明' },
      ],
      foots: {}
    }
  },
  methods: {
    goToOut () {
      window.open("https://beian.miit.gov.cn/#/Integrated/index")
    },
    tofooterdetail (index, item) {
      this.$router.push({
        path: "/PCshop/footerdetail",
        query: { index: index, text: item.text },
      });
    }
  }
}
</script>
<style lang='scss' scoped>
.footer {
  min-width: 1200px;
  width: 100%;
  background-color: #f9634c;
  color: #ffffff;
  font-size: 16px;
  padding: 25px 0;
  .footer1 {
    display: flex;
    margin: 0 auto;
    width: 25%;
    justify-content: space-between;
    margin-top: 28px;
    .foofont {
      cursor: pointer;
      font-size: 18px;
      color: #f3f1f1;
    }
  }
  .footer2 {
    display: flex;
    margin: 0 auto;
    // width: 40%;
    justify-content: center;
    align-items: center;
    margin-top: 28px;
    .foofont2 {
      font-size: 12px;
      color: #f3f1f1;
      margin-right: 20px;
      span {
        cursor: pointer;
      }
    }
  }
}
</style>